<template>
  <router-view></router-view>
</template>
<!-- 语法糖路由，直接引用 -->
<script>
import home from './views/home/homePage.vue'

export default {
  name: 'homePage',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    home
  }
}

</script>
<style lang="scss">
</style>
