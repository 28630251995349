<template>
  <div class="box">
    <!-- 头部 -->
    <div class="bottol">
      <div class="bottol-two">
        <div class="logo">
          <!-- logo图片 -->
          <img src="../img/kpulogo.png" />
        </div>
        <div class="fenye">
          <div class="fenye-jup">
            <li class="shouyetwo">
              <router-link to="/page">首页</router-link>
            </li>
            <li class="shouyetwo">
              <router-link to="/about">平台介绍</router-link>
            </li>
            <li
              class="shouyetwo"
              @mouseover="ishsow = true"
              @mouseleave="ishsow = false"
            >
              <!--router-link to="/shangjia"           商家注册路由 待更改-->
              <router-link to="/business">商家入驻</router-link>
              <div class="shangjia-later" v-show="ishsow">
                <ul>
                  <li class="zhuce-box">
                    <!-- <router-link to="/merchantRegistration" @click="merchant"
                      >商户注册</router-link
                    > -->
                    <div class="registerTwo" @click="registerShop">
                      商户注册
                    </div>
                  </li>
                  <li class="denglu-box">
                    <a href="https://service.kpulife.com/shop" @click="merchant"
                      >商户登录</a
                    >
                  </li>
                </ul>
              </div>
            </li>
            <li
              class="shouyetwo"
              @mouseover="ishsow2 = true"
              @mouseleave="ishsow2 = false"
            >
              <router-link to="/partner">城市合伙人</router-link>
              <div class="hehuo-later" v-show="ishsow2">
                <ul>
                  <li class="hehuozhu-box">
                    <!-- <router-link to="/parterRegistration" @click="parter"
                      >城市合伙人注册</router-link
                    > -->
                    <div class="registerTwo" @click="registerCity">
                      城市合伙人注册
                    </div>
                  </li>
                  <li class="hehuolu-box">
                    <a
                      href="https://service.kpulife.com/citypartner/"
                      @click="parter"
                      >城市合伙人登录</a
                    >
                  </li>
                </ul>
              </div>
            </li>
            <li class="shouyetwo">
              <router-link to="/qwerq">金铺新闻</router-link>
            </li>
          </div>
        </div>
      </div>
    </div>
    <div class="zhuceshoptext" v-show="ishsow3">
      <div class="zhuceshoptext-one">
        <div>商户注册请前往金铺生活App进行注册</div></div>
      <div class="zhuceshoptext-two" @click="zhuceshoptwo">确定</div>
    </div>
    <div class="zhuceshoptext" v-show="ishsow4">
      <div class="zhuceshoptext-one">
        城市合伙人注册请前往金铺生活App进行注册
      </div>
      <div class="zhuceshoptext-two" @click="zhuceshoptwo">确定</div>
    </div>
    <router-view></router-view>
    <!-- 底部盒子 -->
    <div class="bottom">
      <div class="kpulife">开铺（上海）网络科技有限公司 版权所有</div>
      <div class="tatll">联系电话: 0086-021-61681728</div>
      <div class="mall">联系邮箱: zlawyers@163.com</div>
      <div class="beiipc">沪ICP备19036011号</div>
    </div>
  </div>
</template>
<!-- 语法糖路由，直接引用 -->
<script>
export default {
  data () {
    return {
      ishsow: false,
      ishsow2: false,
      ishsow3: false,
      ishsow4: false
    }
  },
  methods: {
    merchant () {
      this.ishsow = false
    },
    registerShop () {
      this.ishsow = false
      this.ishsow4 = false
      this.ishsow3 = true
    },
    parter () {
      this.ishsow2 = false
    },
    registerCity () {
      this.ishsow2 = false
      this.ishsow3 = false
      this.ishsow4 = true
    },
    zhuceshoptwo () {
      this.ishsow3 = false
      this.ishsow4 = false
    }
  }
}
</script>
<style lang="scss">
body {
  // vw 屏幕宽度百分比 1vw = 1%  vh 高度百分比
  padding: auto;
  margin: auto;
  // display: flex;
}
.box {
  width: 100%;
  // display: flex;
  .bottol {
    width: 100%;
    // height: 11%;
    // display: flex;
    background-color: #ffffff;
    z-index: 99999;
    position: fixed;
    top: 0px;
    // 头部盒子样式
    .bottol-two {
      width: 82%;
      // height:80%;
      z-index: 99;
      // display: flex;
      margin: 1% 8%;
      // logo样式
      .logo {
        width: 12%;
        height: 90%;
        margin-top: 0%;
        float: left;
        img {
          width: 100%;
        }
      }
      // 跳转路由盒子样式
      .fenye {
        width: 55%;
        height: 90%;
        margin-left: 60%;
        cursor: pointer;
        .fenye-jup {
          margin: 0;
          width: 100%;
          height: 100%;
          list-style-type: none;
          .shouyetwo {
            height: 100%;
            float: left;
            margin-left: 6%;
            font-size: 1.4vw;
            // text-align: center;
            .router-link-active {
              color: red;
              border-bottom: 0.3vw solid red;
              font-weight: bold;
              z-index: 9999;
            }
            a {
              text-decoration: none;
              color: black;
              // padding-bottom: 0.5rem;
            }
            .shangjia-later {
              width: 8%;
              height: 100%;
              background: linear-gradient(to bottom, #f8f6f600, #ffffff);
              position: absolute;
              right: 19.5%;
              // right: 24.5%;
              top: 60%;
              overflow: hidden;
              transition: all 0.5s;
              .router-link-active {
                color: black;
                font-weight: 500;
              }
              ul {
                text-decoration: none;
                list-style-type: none;
                .zhuce-box {
                  width: 80%;
                  height: 30%;
                  position: absolute;
                  left: 10%;
                  top: 10%;
                  text-decoration: none;
                  font-size: 1.3vw;
                  text-align: center;
                  .router-link-active {
                    color: black;
                    border-bottom: 0px;
                    font-weight: 500;
                  }
                  a:hover {
                    color: red;
                    z-index: 9999;
                  }
                }
                .denglu-box {
                  width: 80%;
                  height: 30%;
                  position: absolute;
                  left: 10%;
                  top: 50%;
                  text-decoration: none;
                  font-size: 1.3vw;
                  text-align: center;
                  a:hover {
                    color: red;
                    z-index: 9999;
                  }
                  .router-link-active {
                    color: black;
                    border-bottom: 0px;
                    font-weight: 500;
                  }
                }
              }
            }
            .hehuo-later {
              width: 11%;
              height: 100%;
              text-align: center;
              background: linear-gradient(to bottom, #f8f6f600, #ffffff);
              position: absolute;
              right: 8.5%;
              // right: 15.5%;
              top: 60%;
              z-index: 99;
              ul {
                text-decoration: none;
                list-style-type: none;
                .hehuozhu-box {
                  width: 100%;
                  height: 30%;
                  position: absolute;
                  left: 0;
                  top: 13%;
                  text-decoration: none;
                  font-size: 1.3vw;
                  text-align: center;
                  .router-link-active {
                    color: black;
                    border-bottom: 0rem;
                    font-weight: 500;
                  }
                }
                a:hover {
                  color: red;
                  z-index: 9999;
                }
                .hehuolu-box {
                  width: 100%;
                  height: 30%;
                  position: absolute;
                  left: 0;
                  top: 50%;
                  text-decoration: none;
                  font-size: 1.3vw;
                  text-align: center;
                  a:hover {
                    color: red;
                    z-index: 9999;
                  }
                  .router-link-active {
                    color: black;
                    border-bottom: 0rem;
                    font-weight: 500;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .registerTwo {
    color: black;
    font-weight: 500;
  }
  .registerTwo:hover {
    color: red;
    z-index: 9999;
  }
  .zhuceshoptext {
    width: 30%;
    height: 18rem;
    background-color: #fff;
    box-shadow:
    0rem 0rem 1rem rgba(66, 62, 62, 0.5),
    0rem 0rem 1rem rgba(66, 62, 62, 0.5),
    0rem 0rem 1rem rgba(66, 62, 62, 0.5),
    0rem 0rem 1rem rgba(66, 62, 62, 0.5);
    border-radius: 1.2rem;
    position: fixed;
    top: 20%;
    right: 35%;
    z-index: 999;
    .zhuceshoptext-one {
      width: 100%;
      height: 12rem;
      text-align: center;
      line-height: 12rem;
      color: rgb(0, 0, 255);
      font-size: 1.4vw;
    }
    .zhuceshoptext-two {
      width: 100%;
      height: 6rem;
      line-height:6rem;
      text-align: center;
      cursor: pointer;
      font-size: 1.2vw;
      color: rgb(252, 0, 0);
    }
  }
  // 底部样式
  .bottom {
    width: 100%;
    height: 12%;
    background-color: #fff;
    font-size: 1vw;
    // border: 1px solid red;
    // display: flex;
    // margin-top: 10%;
    padding-bottom: 5%;
    padding-top: 2%;
    // margin-top: 80%;
    // position:absolute;
    // top: 120%;
    .kpulife {
      width: 40%;
      height: 30%;
      text-align: right;
      float: left;
      // position: relative;
      // top: 65px;
      // left: 26%;
    }
    .tatll {
      width: 20%;
      height: 30%;
      text-align: center;
      float: left;
      // position: relative;
      // top: 45px;
      // left: 45%;
    }
    .mall {
      width: 35%;
      height: 30%;
      float: left;
      // position: relative;
      // top: 25px;
      // left: 60%;
    }
    .beiipc {
      width: 100%;
      height: 30%;
      padding-top: 1%;
      text-align: center;
      float: left;
      // position: relative;
      // top: 31px;
      // left: 47%;
    }
  }
}
</style>
