import { createRouter, createWebHistory } from 'vue-router'
// import indexPage from '../views/index/indexPage.vue'
// import About from '../views/pingtai/Pingtai-about.vue'
// import Shangjia from '../views/shangjia/Shangjia-settled.vue'
// import City from '../views/city/City-partner.vue'
// import news from '../views/xinwen/xinwen-home.vue'

// 路由跳转   路径
const routes = [
  {
    path: '/',
    name: 'homePage',
    component: () => import('../views/home/homePage.vue'),
    redirect: '/page',
    children: [
      {
        path: '/page',
        name: 'page',
        // component: About
        component: () => import(/* webpackChunkName: "about" */ '../views/home/home-neir.vue')
      },
      {
        path: '/about',
        name: 'about',
        // component: About
        component: () => import(/* webpackChunkName: "about" */ '../views/pingtai/Pingtai-about.vue')
      },
      {
        path: '/business',
        name: 'business',
        // component: Shangjia
        component: () => import('../views/shangjia/Shangjia-settled.vue')
      },
      {
        path: '/partner',
        name: 'partner',
        // component: City
        component: () => import('../views/city/City-partner.vue')
      },
      // 商家注册路由路径
      {
        path: '/merchantRegistration',
        name: 'merchantRegistration',
        // component: City
        component: () => import('../views/shangjia/Shangjia-zhuce.vue')
      },
      {
        path: '/parterRegistration',
        name: 'parterRegistration',
        // component: City
        component: () => import('../views/city/Partner-zhuce.vue')
      },
      {
        path: '/qwerq',
        name: 'qwerq',
        // component: xinwen
        component: () => import('../views/xinwen/Xinwen-more.vue')
      },
      {
        path: '/alone',
        name: 'alone',
        // component: xinwen
        component: () => import('../views/xinwen/Xinwen-only.vue')
      },
      {
        path: '/PrivacyAgreement',
        name: 'PrivacyAgreement',
        // 隐私政策
        component: () => import('../views/home/Privacy-agreement.vue')
      },
      {
        path: '/serviceAgreement',
        name: 'serviceAgreement',
        // component: City商家服务协议
        component: () => import('../views/home/erchant-serviceAgreement.vue')
      }
    ]
  },
  {
    path: '/serveice',
    name: 'serveice',
    // component: About
    component: () => import(/* webpackChunkName: "about" */ '../views/KpulifServeice.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
